import { type LinkProps } from 'react-router'
import { type lngShort } from '~/utils/i18n.utils'

export const getHref = (to: LinkProps['to'], lang: lngShort): string => {
	const path = String(to)
	if (lang === 'sv' && path === '/') {
		return path
	}
	if (lang !== 'sv' && path === '/') {
		return `/${lang}`
	}
	if (lang !== 'sv' && path !== '/') {
		return `/${lang}${to}`
	}
	return String(to)
}
